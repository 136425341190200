import React, { Component } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import imgHero from "../images/gfx/hero-article.jpg"
import imgMobileHero from "../images/gfx/mobile-hero-2.jpg"


import imgTicketScan from "../images/passenger-scan-mobile-ticket-coach.jpg"
import imgProfileChrisBell from "../images/chris-bell-profile-square.jpg"
import "./index.css";
import "./case-study.css";
import "./article.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Contact from "../components/contact"
import Footer from "../components/footer"
import Nav from "../components/nav"

class BlogPage extends Component {

  componentDidMount(){
    AOS.init({
      duration : 2000
    });
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="ShuttleID now supports local authorities" />
        <Nav activeTab="blog" />
        <div>
          <div className="case-study">

              <div className="hero-article" style={{ backgroundImage: `url(${imgHero})`, backgroundColor: '#063a70', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'left center' }}>
                <h1 className="blog-headline">ShuttleID launches new package for local authorities</h1>
              </div>

              <div className="mobile-hero-article" style={{ backgroundImage: `url(${imgMobileHero})`, backgroundColor: '#084e96', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'center top' }}>
                <h1 className="blog-headline">ShuttleID launches new package for local authorities</h1>
              </div>

              <div>

                <div className="article">
                  <div className='article-intro article-inner'>
                    <p>ShuttleID now provides support for local authorities to directly use the platform to manage an entire home-to-school network. The new offering includes a <strong>90-day free trial</strong>.</p>
                    <p><img src={imgTicketScan} alt="" /></p>
                  </div>
                  <div className='article-inner'>
                    <p>Whilst the platform is already used by individual transport operators on behalf of councils, the new package formalises that ShuttleID can also support local authorities directly across an entire school bus network. The decision to make a separate offer direct to local authorities follows an unprecedented level of interest from councils in the previous twelve months. ShuttleID have signed up a number of local authorities already and more are currently trialling the system.</p>

                    <h2>Capacity control</h2>
                    <p>The States of Guernsey were one of the first authorities to sign up. The system was put in place primarily to tackle a recent surge in school bus demand on the island. Whilst the States of Guernsey acknowledge the reduction in car travel as being positive, it has in turn created issues with pupils being unable to board due to insufficient capacity.</p>
                    <p>ShuttleID now provides the States of Guernsey a simple way to control capacity by issuing digital bus passes. Reporting helps identify dormant passes across their network, allowing the authority to better reallocate capacity to users currently on a waiting list.</p>
                    <p>Jonathan Guilbert, Senior Passenger Transport Officer, States of Guernsey said: “ShuttleID provides an effective way of managing seating capacity on services. It is simple to use, both from a driver as well as back-office point of view. We find the reporting features within ShuttleID are very useful. Technical support of the system is excellent with queries responded to quickly.”</p>
                    <p>“Parents have benefited from the ability to be able to track a service in real time. If a pupil lives near a bus stop, they need only leave the house when they know that the service is near. This is particularly useful in bad weather. Parents also report that they like boarding notifications so that they can be informed when their child has safely boarded the bus.”</p>

                    <h2>Improving service delivery and saving costs</h2>
                    <p>Northumberland County Council also signed up following a successful trial earlier this year. The authority is in the process of scaling out the system across their school bus network. Thomas Bunting, Passenger Transport Officer, Northumberland County Council said: </p>
                    <p>"ShuttleID is proving to be a real game changer in the way we manage the home-to-school transport network in Northumberland. The system has enabled us to transition from a point where we had little or no data on travel permit checks or usage, to a point where we now have access to extensive data streams which we can use to improve the delivery of the service and achieve cost savings. This has transformed how drivers manage access onto vehicles and has also had a positive impact on student behaviour.”</p>
                    <p>“Whilst we have to date only a limited rollout of the ShuttleID product, it is clear that it is already offering us a fantastic solution in terms of the management and tracking of school transport. As a result we are intent on extending its use across the entire school transport network, not just on our contracted PCV vehicles but taxi and private hire vehicles as well."</p>
                    <p>ShuttleID will be exhibiting the new package at the Euro Bus Expo on Nov 12-14 at the NEC, Birmingham. More information about the package can be requested by contacting ShuttleID at <a className="link" href="mailto:info@shuttleid.uk">info@shuttleid.uk</a> or calling 0333 344 9868.</p>
                    <div className='author' style={{ border: '1px solid #d5e6f5', background: '#f3f9ff', marginBottom: '10px' }}>
                      <div className='author__img'>
                        <img src={imgProfileChrisBell} alt="Chris Bell" />
                      </div>
                      <div className='author__quote' style={{  }}>
                        <p style={{ marginBottom: 10 }}><strong>About the author</strong></p>
                        <p style={{ marginBottom: 10 }}>Chris Bell is a director at ShuttleID; the affordable, digital bus pass and ticketing system that keeps you in control.</p>
                        <p style={{ marginBottom: 10 }}>During a 20 year career in technology, Chris worked on many systems for coach companies and saw the issues of managing home-to-school first hand, leading to the launch of ShuttleID in 2019.</p>
                        <p style={{ margin: 0 }}>Email: <a className="link" href="mailto:info@shuttleid.uk">info@shuttleid.uk</a> | Phone: 0333 344 9868</p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <Contact />
            </div>
        </div>
        <Footer />
      </Layout>
    );
  }
};

export default BlogPage